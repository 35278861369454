import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconAdd = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <rect width="26" height="26" stroke={color} strokeWidth="2" rx="6" />
      <rect width="2" height="14" x="12" y="6" fill={color} rx="1" />
      <rect width="2" height="14" x="12" y="6" fill={color} rx="1" transform="rotate(90 13 13)" />
    </g>
  </svg>
);

IconAdd.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

IconAdd.defaultProps = {
  className: null,
  color: colors.gray40
};
