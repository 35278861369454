import React from 'react';
import PropTypes from 'prop-types';

export const IconShareIos = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19" height="26" viewBox="0 0 19 26">
    <g fill="none" fillRule="evenodd" transform="translate(1)">
      <path stroke="#2B9CFF" strokeLinecap="round" strokeWidth="1.5" d="M11.977 8.275H15a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-12a2 2 0 0 1 2-2h2.993" />
      <rect width="1.5" height="16" x="7.75" fill="#2B9CFF" rx=".75" />
      <rect width="1.5" height="6" x="5.506" fill="#2B9CFF" rx=".75" transform="rotate(49 6.256 3)" />
      <rect width="1.5" height="6" x="10" fill="#2B9CFF" rx=".75" transform="scale(-1 1) rotate(49 0 -20.589)" />
    </g>
  </svg>
);

IconShareIos.propTypes = {
  className: PropTypes.string,
};

IconShareIos.defaultProps = {
  className: null,
};
