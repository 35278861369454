import React from 'react';
import PropTypes from 'prop-types';

export const IconAndroid = ({ className }) => (
  <svg className={className} width="35" height="35" viewBox="0 0 20 20">
    <path fill="#91c238" d="M16.041 6.706v8.006c0 .368-.128.68-.386.937a1.271 1.271 0 01-.93.385h-.905v2.728c0 .345-.12.637-.362.877s-.536.361-.882.361-.64-.12-.881-.36a1.192 1.192 0 01-.362-.878v-2.728H9.667v2.728c0 .345-.121.637-.362.877-.242.24-.536.361-.882.361-.338 0-.628-.12-.87-.36a1.192 1.192 0 01-.361-.878l-.012-2.729h-.894c-.37 0-.684-.128-.942-.384a1.272 1.272 0 01-.386-.938V6.706h11.083zM3.244 6.478c.338 0 .628.12.869.36.241.241.362.53.362.866v5.168c0 .345-.119.638-.356.878-.238.24-.53.36-.875.36-.347 0-.64-.12-.882-.36A1.191 1.191 0 012 12.872V7.704c0-.336.12-.625.362-.865s.535-.36.882-.36zm14.513 0c.345 0 .64.119.88.355.242.236.363.527.363.871v5.168c0 .345-.12.638-.362.878s-.536.36-.881.36c-.338 0-.628-.12-.87-.36a1.191 1.191 0 01-.362-.878V7.704c0-.344.12-.635.362-.871a1.2 1.2 0 01.87-.355zM13.797.096c.056-.096.136-.12.24-.072.097.056.117.136.061.24l-.857 1.575a5.308 5.308 0 012.064 1.845 4.64 4.64 0 01.773 2.59H4.91c0-.938.258-1.8.773-2.59A5.286 5.286 0 017.76 1.839L6.902.265c-.056-.105-.036-.184.06-.24.105-.05.186-.025.242.071l.87 1.587a5.958 5.958 0 012.426-.505c.853 0 1.662.168 2.427.505zM7.951 3.33a.435.435 0 00-.326.138.46.46 0 00-.132.33.46.46 0 00.132.33.433.433 0 00.326.14c.13 0 .24-.047.333-.14a.452.452 0 00.138-.33.45.45 0 00-.138-.33.455.455 0 00-.333-.138zm5.096 0a.454.454 0 00-.333.138.45.45 0 00-.138.33c0 .129.046.238.138.33a.453.453 0 00.333.14.434.434 0 00.326-.14.46.46 0 00.132-.33.46.46 0 00-.132-.33.435.435 0 00-.326-.138z" />
  </svg>
);

IconAndroid.propTypes = {
  className: PropTypes.string,
};

IconAndroid.defaultProps = {
  className: null,
};
