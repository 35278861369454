import React from 'react';
import PropTypes from 'prop-types';

export const IconIos = ({ className }) => (
  <svg className={className} width="35" height="35" viewBox="0 0 28 28">
    <path fill="#fff" d="M19.09 6.492c.15 0 .3.007.445.021.997.043 3.476.4 5.093 2.766a.224.224 0 01-.064.315l-.022.014c-.47.29-2.812 1.894-2.782 4.923.032 3.727 3.115 5.094 3.466 5.238l.017.007a.224.224 0 01.119.275l-.008.025c-.19.611-.757 2.212-1.885 3.86-1.08 1.58-2.306 3.369-4.302 3.406-.932.018-1.564-.257-2.177-.522-.621-.269-1.265-.547-2.272-.547-1.06 0-1.735.287-2.388.566-.581.247-1.183.504-2.015.537a2.568 2.568 0 01-.101.002c-1.772 0-3.065-1.659-4.343-3.506-2.56-3.696-4.03-9.791-1.716-13.808C5.372 7.947 7.58 6.61 9.916 6.576h.071c1.005 0 1.954.376 2.792.708.626.249 1.167.463 1.583.463.371 0 .91-.212 1.532-.457.902-.356 2.024-.798 3.196-.798zM19.537 0a.226.226 0 01.232.2c.164 1.496-.378 3.166-1.451 4.467-1.02 1.233-2.512 1.998-3.895 1.998a3.79 3.79 0 01-.29-.011.224.224 0 01-.206-.194c-.221-1.679.62-3.344 1.483-4.343C16.424.93 18.122.057 19.537 0z" />
  </svg>
);

IconIos.propTypes = {
  className: PropTypes.string,
};

IconIos.defaultProps = {
  className: '',
};
