import React from 'react';
import classNames from 'classnames';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

export const QrCode = ({ className, domain }) => (
  <div className={classNames('qr-wrapper align-items-center d-flex justify-content-center', className)}>
    <QRCode
      value={domain}
      size={131}
      level="H"
      renderAs="svg"
    />
  </div>
);

QrCode.propTypes = {
  className: PropTypes.string,
  domain: PropTypes.string.isRequired
};

QrCode.defaultProps = {
  className: null,
};
