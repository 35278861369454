import React from 'react';
import PropTypes from 'prop-types';

export const IconIncognito = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <g fill="none" fillRule="nonzero">
      <path fill="#E1EBF0" d="M15.87 21.176a.835.835 0 0 1-.603.25.842.842 0 0 1-.524-.18l-.098-.078a2.686 2.686 0 0 0-1.592-.55 2.69 2.69 0 0 0-1.734.58l-.073.059a.84.84 0 0 1-.527.182.832.832 0 0 1-.631-.282c-.279-.323-.217-.801.12-1.072l.056-.045a4.367 4.367 0 0 1 2.789-.946 4.375 4.375 0 0 1 2.737.989.73.73 0 0 1 .08 1.093zM2.314 21.027H.838c-.448 0-.811-.341-.811-.762 0-.42.363-.762.811-.762h1.476c.448 0 .811.341.811.762 0 .42-.363.762-.81.762z" />
      <path fill="#B4D2D7" d="M25.143 21.027h-1.476c-.448 0-.811-.341-.811-.762 0-.42.363-.762.811-.762h1.476c.448 0 .811.341.811.762 0 .42-.363.762-.811.762z" />
      <path fill="#C19ED2" d="M25.954 10.678c0 1.54-1.44 2.926-4.052 3.903-2.377.888-5.52 1.377-8.85 1.377s-6.472-.489-8.849-1.377C1.59 13.604.151 12.218.151 10.678c0-.85.446-2.102 2.573-3.243.197-.105.403-.205.62-.3a11 11 0 0 1 .535-.22l.489-.184c.025-.236.052-.464.082-.687.422-3.147 1.36-5.03 2.854-5.737 1.828-.866 3.843.305 5.046 1.005.23.134.532.31.703.387.17-.077.471-.253.702-.387 1.203-.7 3.218-1.871 5.046-1.005 1.477.7 2.411 2.548 2.84 5.634.035.255.067.518.096.79l.49.185c.158.06.311.122.458.185.245.105.475.216.696.334 2.127 1.141 2.573 2.393 2.573 3.243z" />
      <path fill="#B4D2D7" d="M15.87 21.176a.835.835 0 0 1-.603.25.842.842 0 0 1-.524-.18l-.098-.078a2.686 2.686 0 0 0-1.592-.55v-1.524a4.375 4.375 0 0 1 2.737.989.73.73 0 0 1 .08 1.093z" />
      <path fill="#B082B5" d="M25.954 10.678c0 1.54-1.44 2.926-4.052 3.903-2.377.888-5.52 1.377-8.85 1.377V1.7c.171-.077.472-.253.703-.387 1.203-.7 3.218-1.871 5.046-1.005 1.477.7 2.411 2.548 2.84 5.634.035.255.067.518.096.79l.49.185c.158.06.311.122.458.185.245.105.475.216.696.334 2.127 1.141 2.573 2.393 2.573 3.243z" />
      <path fill="#722EA2" d="M22.685 7.1c-.7.498-1.411.903-1.432.914-2.297 1.179-5.203 1.827-8.2 1.827-2.99 0-5.887-.645-8.18-1.817-.033-.016-.869-.428-1.53-.89a11 11 0 0 1 .536-.218l.489-.185c.025-.236.052-.464.082-.687.55.34 1.204.644 1.24.662 2.053 1.04 4.658 1.61 7.363 1.61 2.432 0 4.785-.462 6.728-1.311.121-.053.95-.522 1.86-1.064.035.255.067.518.096.79l.49.185c.158.06.311.122.458.185z" />
      <path fill="#98C2EB" d="M11.409 21.302c0 2.59-2.243 4.698-5 4.698s-5-2.108-5-4.698c0-2.59 2.243-4.697 5-4.697s5 2.107 5 4.697z" />
      <path fill="#82A7CC" d="M24.558 21.302c0 2.59-2.243 4.698-5 4.698s-5-2.108-5-4.698c0-2.59 2.243-4.697 5-4.697s5 2.107 5 4.697z" />
      <path fill="#531F78" d="M22.685 7.1c-.7.498-1.411.903-1.432.914-2.297 1.179-5.203 1.827-8.2 1.827V8.317c2.432 0 4.785-.463 6.728-1.312.121-.053.95-.522 1.86-1.064.035.255.067.518.096.79l.49.185c.158.06.311.122.458.185z" />
    </g>
  </svg>
);

IconIncognito.propTypes = {
  className: PropTypes.string,
};

IconIncognito.defaultProps = {
  className: null,
};
