import React from 'react';
import PropTypes from 'prop-types';

export const IconCloseCircle = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill={color} opacity=".1" />
      <path fill="#232323" d="M16.377 14.963l-2.96-2.96 2.958-2.96a1 1 0 1 0-1.414-1.414l-2.959 2.96L9.04 7.625A1 1 0 1 0 7.626 9.04l2.962 2.962L7.63 14.96a1 1 0 1 0 1.414 1.414l2.958-2.958 2.961 2.961a.997.997 0 0 0 1.414 0 1 1 0 0 0 0-1.414" opacity=".4" />
    </g>
  </svg>
);

IconCloseCircle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconCloseCircle.defaultProps = {
  className: '',
  color: '#D8D8D8',
};
