import React from 'react';
import PropTypes from 'prop-types';

export const IconChrome = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g fill="none" fillRule="nonzero">
      <ellipse cx="14.026" cy="14.07" fill="#FFF" rx="13.778" ry="13.757" />
      <path fill="#000" fillOpacity=".1" d="M22.793 16.571c0-4.835-3.925-8.754-8.767-8.754-4.843 0-8.768 3.92-8.768 8.754h2.505a6.258 6.258 0 0 1 6.262-6.253c3.46 0 6.263 2.8 6.263 6.253" />
      <ellipse cx="14.652" cy="15.821" fill="#000" fillOpacity=".1" rx="4.76" ry="4.752" />
      <ellipse cx="14.026" cy="14.07" fill="url(#icon-chrome-a)" rx="5.135" ry="5.127" />
      <path fill="url(#icon-chrome-b)" d="M26.55 7.817C23.103.89 14.684-1.935 7.746 1.507a14.02 14.02 0 0 0-5.368 4.684l5.637 9.755a6.252 6.252 0 0 1 5.886-8.13" />
      <path fill="url(#icon-chrome-c)" d="M2.377 6.191c-4.313 6.426-2.594 15.126 3.841 19.434a14.042 14.042 0 0 0 6.68 2.327l5.887-10.005a6.269 6.269 0 0 1-10.771-2" />
      <path fill="url(#icon-chrome-d)" d="M12.898 27.952c7.726.583 14.462-5.198 15.046-12.911a13.986 13.986 0 0 0-1.394-7.224H13.9a6.258 6.258 0 0 1 6.234 6.282 6.248 6.248 0 0 1-1.349 3.848" />
      <path fill="url(#icon-chrome-e)" d="M2.377 6.191l5.637 9.755a6.244 6.244 0 0 1 .25-4.252L2.628 5.816" />
      <path fill="url(#icon-chrome-f)" d="M12.898 27.952l5.887-10.005a6.266 6.266 0 0 1-3.632 2.126l-2.505 7.879" />
      <path fill="url(#icon-chrome-g)" d="M26.55 7.817H13.9a6.266 6.266 0 0 1 4.885 2.376l8.016-2" />
    </g>
  </svg>
);

IconChrome.propTypes = {
  className: PropTypes.string,
};

IconChrome.defaultProps = {
  className: null,
};
