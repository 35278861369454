import React from 'react';
import PropTypes from 'prop-types';

export const IconLogoMobile = ({ className }) => (
  <svg
    className={className}
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-logo-mobile-gradient-2)">
      <rect width="58" height="58" rx="3.22222" fill="#141A20" />
      <g clipPath="url(#icon-logo-mobile-gradient-3)">
        <path d="M25.1507 28.8725C24.2413 26.5859 22.6417 24.2335 22.006 23.3418C21.9748 23.2979 21.934 23.2622 21.8868 23.2375C21.8396 23.2129 21.7874 23.2 21.7345 23.2C21.6815 23.2 21.6294 23.2129 21.5822 23.2375C21.535 23.2622 21.4942 23.2979 21.463 23.3418C20.8272 24.2335 19.2277 26.5859 18.3183 28.8725C17.4754 30.992 17.148 33.1987 17.0229 34.4259C17.0157 34.4969 17.03 34.5684 17.064 34.6306C17.098 34.6928 17.1499 34.7427 17.2126 34.7734C17.2754 34.8041 17.3458 34.8141 17.4143 34.8021C17.4828 34.7901 17.546 34.7565 17.5951 34.7061C18.1494 34.141 18.7326 33.6065 19.342 33.1047C20.0452 32.5347 20.7902 32.0211 21.5702 31.5684C21.6204 31.5394 21.6771 31.5241 21.7348 31.5241C21.7925 31.5241 21.8492 31.5394 21.8994 31.5684C22.6791 32.0213 23.4238 32.5349 24.127 33.1047C24.7365 33.6063 25.3196 34.1409 25.8738 34.7061C25.923 34.7565 25.9862 34.7901 26.0547 34.8021C26.1232 34.8141 26.1936 34.8041 26.2563 34.7734C26.3191 34.7427 26.371 34.6928 26.4049 34.6306C26.4389 34.5684 26.4533 34.4969 26.4461 34.4259C26.3216 33.1987 25.9935 30.992 25.1507 28.8725Z" fill="url(#icon-logo-mobile-gradient-1)" />
        <path d="M22.5635 32.9787C22.3187 32.7999 21.9463 32.5836 21.7991 32.4994C21.7798 32.4885 21.7582 32.4828 21.7362 32.4828C21.7143 32.4828 21.6926 32.4885 21.6734 32.4994C21.5261 32.5836 21.1531 32.7999 20.9083 32.9787C20.7 33.1325 20.502 33.3004 20.3156 33.4814C20.2944 33.5015 20.2803 33.5283 20.2755 33.5575C20.2708 33.5868 20.2757 33.6168 20.2894 33.6429C20.3031 33.669 20.3249 33.6896 20.3514 33.7015C20.3778 33.7135 20.4074 33.716 20.4354 33.7088L21.7087 33.4077C21.7283 33.4025 21.7489 33.4025 21.7686 33.4077L23.0425 33.7088C23.0705 33.716 23.1 33.7135 23.1264 33.7015C23.1529 33.6896 23.1747 33.669 23.1884 33.6429C23.2021 33.6168 23.207 33.5868 23.2023 33.5575C23.1975 33.5283 23.1834 33.5015 23.1622 33.4814C22.9739 33.3002 22.7739 33.1323 22.5635 32.9787Z" fill="white" />
        <mask id="mask0_1447_36828" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="17" y="23" width="10" height="12">
          <path fillRule="evenodd" clipRule="evenodd" d="M22.0061 23.3418C22.6419 24.2335 24.2415 26.5859 25.1508 28.8725C25.9937 30.992 26.3217 33.1987 26.4463 34.4259C26.4535 34.4969 26.4391 34.5684 26.4051 34.6306C26.3711 34.6928 26.3192 34.7427 26.2565 34.7734C26.1938 34.8041 26.1233 34.8141 26.0548 34.8021C25.9863 34.7901 25.9231 34.7565 25.8739 34.7061C25.3198 34.1409 24.7367 33.6063 24.1271 33.1047C23.424 32.5349 22.6793 32.0213 21.8996 31.5684C21.8493 31.5394 21.7926 31.5241 21.7349 31.5241C21.6773 31.5241 21.6206 31.5394 21.5703 31.5684C20.7903 32.0211 20.0454 32.5347 19.3422 33.1047C18.7327 33.6065 18.1496 34.141 17.5953 34.7061C17.5461 34.7565 17.4829 34.7901 17.4144 34.8021C17.346 34.8141 17.2755 34.8041 17.2128 34.7734C17.1501 34.7427 17.0982 34.6928 17.0642 34.6306C17.0302 34.5684 17.0158 34.4969 17.023 34.4259C17.1481 33.1987 17.4756 30.992 18.3185 28.8725C19.2278 26.5859 20.8274 24.2335 21.4632 23.3418C21.4943 23.2979 21.5352 23.2622 21.5823 23.2375C21.6295 23.2129 21.6817 23.2 21.7346 23.2C21.7876 23.2 21.8398 23.2129 21.8869 23.2375C21.9341 23.2622 21.975 23.2979 22.0061 23.3418ZM21.7992 32.4994C21.9465 32.5836 22.3189 32.7999 22.5637 32.9787C22.7741 33.1323 22.9741 33.3002 23.1624 33.4814C23.1836 33.5015 23.1977 33.5283 23.2024 33.5575C23.2072 33.5868 23.2023 33.6168 23.1886 33.6429C23.1748 33.669 23.153 33.6896 23.1266 33.7015C23.1002 33.7135 23.0706 33.716 23.0426 33.7088L21.7687 33.4077C21.7491 33.4025 21.7285 33.4025 21.7089 33.4077L20.4355 33.7088C20.4075 33.716 20.378 33.7135 20.3515 33.7015C20.3251 33.6896 20.3033 33.669 20.2896 33.6429C20.2758 33.6168 20.271 33.5868 20.2757 33.5575C20.2805 33.5283 20.2946 33.5015 20.3158 33.4814C20.5022 33.3004 20.7002 33.1325 20.9085 32.9787C21.1533 32.7999 21.5263 32.5836 21.6735 32.4994C21.6928 32.4886 21.7144 32.4829 21.7364 32.4829C21.7584 32.4829 21.78 32.4886 21.7992 32.4994Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1447_36828)">
          <rect opacity="0.2" x="21.7136" y="22.9747" width="5.0025" height="11.9392" fill="black" />
        </g>
        <path d="M4.3257 27.2686H9.27887V28.3821H5.4284C5.1201 28.3821 4.86866 28.6893 4.86866 29.0703V32.0126C4.86866 32.3918 5.1201 32.7003 5.4284 32.7003H9.27887V33.815H4.3257C3.53968 33.815 2.90033 33.0573 2.90033 32.1257V28.9566C2.90033 28.0256 3.53968 27.2686 4.3257 27.2686Z" fill="white" />
        <path d="M16.4041 28.2389C16.4938 28.4671 16.5395 28.7111 16.5388 28.9572V29.4095C16.5388 29.9147 16.2994 30.3497 15.951 30.5415C16.297 30.7332 16.5388 31.1683 16.5388 31.6734V33.815H14.5711V31.5603C14.5711 31.3059 14.4184 31.0988 14.2316 31.0988H12.1286V33.815H10.1609V27.2679H15.1134C15.6636 27.2679 16.1701 27.6489 16.4041 28.2389ZM12.1286 28.3827V29.9841H14.2316C14.4184 29.9841 14.5711 29.777 14.5711 29.5226V29.0703C14.5711 28.6912 14.3197 28.3827 14.0114 28.3827H12.1286Z" fill="white" />
        <path d="M28.3662 27.2686H33.3194V28.3821H29.4689C29.1606 28.3821 28.9092 28.6893 28.9092 29.0703V29.2965C28.9092 29.6756 29.1606 29.9835 29.4689 29.9835H31.8941C32.2256 29.9869 32.5437 30.1186 32.7848 30.3522C32.9502 30.5154 33.0824 30.7105 33.1739 30.9264C33.2653 31.1424 33.3142 31.3747 33.3176 31.6101C33.3176 31.628 33.3176 31.6501 33.3176 31.6716V32.1245C33.3176 32.1448 33.3176 32.1638 33.3176 32.1859C33.3115 32.4118 33.2665 32.6349 33.1847 32.8447C33.0959 33.0732 32.9592 33.2788 32.7842 33.4469C32.5435 33.6808 32.2255 33.8125 31.8941 33.8156H26.9409V32.7015H30.7914C31.0997 32.7015 31.3511 32.3943 31.3511 32.0139V31.7883C31.3511 31.4086 31.0997 31.1001 30.7914 31.1001H28.3662C28.0345 31.0974 27.7162 30.9656 27.4755 30.7314C27.3102 30.5684 27.1781 30.3734 27.0867 30.1577C26.9952 29.942 26.9463 29.7099 26.9427 29.4747C26.9427 29.4526 26.9427 29.4329 26.9427 29.4132V28.9603C26.9427 28.9394 26.9427 28.9179 26.9427 28.8989C26.949 28.6744 26.994 28.4528 27.0756 28.2444C27.1643 28.0162 27.301 27.811 27.476 27.6434C27.7159 27.4073 28.034 27.2734 28.3662 27.2686Z" fill="white" />
        <path d="M38.6122 27.2679H40.5799V33.815H38.6122V31.0988H36.1697V33.815H34.2014V27.2679H36.1697V29.9841H38.6122V27.2679Z" fill="white" />
        <path d="M42.8873 27.2686H47.8405V28.3827H43.99C43.6817 28.3827 43.4308 28.6899 43.4308 29.0709V29.9841H45.6356V31.0989H43.4308V32.7015H47.8405V33.8163H41.4619V28.9579C41.4619 28.0256 42.1013 27.2686 42.8873 27.2686Z" fill="white" />
        <path d="M54.9658 28.2389C55.0555 28.4671 55.1012 28.7111 55.1005 28.9572V29.4095C55.1005 29.9147 54.861 30.3497 54.5126 30.5415C54.8586 30.7332 55.1005 31.1683 55.1005 31.6734V33.815H53.1327V31.5603C53.1327 31.3059 52.9807 31.0988 52.7933 31.0988H50.6903V33.815H48.7225V27.2679H53.6757C54.2253 27.2679 54.7317 27.6489 54.9658 28.2389ZM50.6903 28.3827V29.9841H52.7933C52.9807 29.9841 53.1327 29.777 53.1327 29.5226V29.0703C53.1327 28.6912 52.8813 28.3827 52.573 28.3827H50.6903Z" fill="white" />
      </g>
    </g>
  </svg>
);

IconLogoMobile.propTypes = {
  className: PropTypes.string,
};

IconLogoMobile.defaultProps = {
  className: '',
};
